jQuery(document).ready(function ($) {
    var body = $('body');

    //$(document).snowfall({deviceorientation : true, round : true, minSize: 1, maxSize:8,  flakeCount : 250});

    $(window).scroll(function() {

        if ($(this).scrollTop() > 5) {

            if (!body.hasClass('sticky')) {
                body.addClass('sticky');
            }

        } else {
            body.removeClass('sticky');
        }


    }); // Scroll End

}); // DRF